import React from 'react';
import Image from '../components/Image';
import * as Forms from '../components/Forms';
import './ImageAndForm.scss';

export const ImageAndForm = (props) => {
  const getForm = (form) => {
    const { location } = props;
    switch(form) {
      case 'contact':
        return <Forms.Contact location={location} />;
      default:
        return null;
    }
  }
  const {
    heading,
    content,
    form,
    image
  } = props;
  return (
    <section className="image-and-form">
      <div className="wrapper">
        <div className="image-and-form-form-container">
          {heading && <span className="image-and-form-heading" dangerouslySetInnerHTML={{__html: heading}} />}
          {content && <div className="image-and-form-content" dangerouslySetInnerHTML={{__html: content}} />}
          <div clasName="image-and-form-form">
            {getForm(form)}
          </div>
        </div>
        <div className="image-and-form-image">
          <div className="image-container">
            <div className="inner">
              <Image image={image} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
